import styled from 'styled-components'

export const Container = styled.iframe`
background-color: #000;
height: 100%;
width: 100vw;

`


export const Iframe = styled.iframe`
  width: 100vw;
  height: 100vh;
  border: none;
  z-index: 99999 ;
`
